import styled from 'styled-components';
import Background from '../../static/images/signup-background.svg';

const HeroWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background-image: url(${Background});
  background-size: cover;
  background-position: center;
  background-color: ${p => p.theme.colors.grey20};

  @media (max-width: ${p => p.theme.breakpoints.maxXs}) {
    padding-bottom: 40px;
  }
`;

export default HeroWrapper;
