import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import styled from 'styled-components';
import { position } from 'styled-system';

import { Box, Flex } from '@components';

const Container = styled.div`
  position: relative;
  z-index: 1;
  flex: 1;
  padding: 100px 0 160px;
  display: flex;
  flex-direction: row;
  /* background-color: ${p => p.theme.colors.grey20}; */
  overflow: hidden;
  min-height: 100vh;

  @media (max-width: ${p => p.theme.breakpoints.maxXs}) {
    padding: 60px 0 32px;
    flex: none;
    min-height: 0;
  }
`;

const Float = styled.div`
  user-select: none;
  position: absolute;
  ${position}

  @media (max-width: ${p => p.theme.breakpoints.maxXs}) {
    display: none;
  }
`;

const Hero = ({ children }) => {
  // const { towel, screen, matt } = useStaticQuery(
  //   graphql`
  //     query {
  //       towel: file(relativePath: { eq: "towel.png" }) {
  //         childImageSharp {
  //           fixed(height: 365, width: 346) {
  //             ...GatsbyImageSharpFixed_withWebp_tracedSVG
  //           }
  //         }
  //       }
  //       screen: file(relativePath: { eq: "screen.png" }) {
  //         childImageSharp {
  //           fixed(traceSVG: { color: "#000" }, height: 562, width: 770) {
  //             ...GatsbyImageSharpFixed_withWebp_tracedSVG
  //           }
  //         }
  //       }
  //       matt: file(relativePath: { eq: "matt.png" }) {
  //         childImageSharp {
  //           fixed(traceSVG: { color: "#211447" }, height: 259, width: 738) {
  //             ...GatsbyImageSharpFixed_withWebp_tracedSVG
  //           }
  //         }
  //       }
  //     }
  //   `
  // );

  return (
    <Container>
      <Box flex="1">
        <Box
          maxWidth="996px"
          minHeight="100%"
          px={['16px', '20px']}
          position="relative"
        >
          <Float
            top="-100px"
            left="-600px"
            style={{ transform: 'rotate(17deg)' }}
          >
            {/* <Img
              alt="Blossom yogamat"
              height="259"
              width="738"
              fixed={matt.childImageSharp.fixed}
            /> */}
          </Float>
          <Float bottom="-290px" left="-180px">
            {/* <Img
              alt="White towel"
              height="365"
              width="346"
              fixed={towel.childImageSharp.fixed}
            /> */}
          </Float>
          <Float top="200px" right="-520px">
            {/* <Img
              alt="Blossom admin schedule on tablet"
              height="562"
              width="738"
              fixed={screen.childImageSharp.fixed}
            /> */}
          </Float>

          <Flex
            flexDirection="column"
            alignItems="flex-start"
            maxWidth="680px"
            mx="auto"
          >
            {children}
          </Flex>
        </Box>
      </Box>
    </Container>
  );
};

export default Hero;
