import React from 'react';
import styled from 'styled-components';

const FieldShift = styled.div`
  left: -9999px;
  position: absolute;

  html[dir='rtl'] & {
    right: -9999px;
    left: auto;
  }
`;

const MailchimpFields = () => {
  return (
    <>
      {/* Anti robot */}
      <FieldShift aria-label="Please leave the following three fields empty">
        <label htmlFor="b_name">Name: </label>
        <input
          type="text"
          name="b_name"
          tabIndex="-1"
          defaultValue=""
          placeholder="Freddie"
          id="b_name"
        />

        <label htmlFor="b_email">Email: </label>
        <input
          type="email"
          name="b_email"
          tabIndex="-1"
          defaultValue=""
          placeholder="youremail@gmail.com"
          id="b_email"
        />

        <label htmlFor="b_comment">Comment: </label>
        <textarea
          name="b_comment"
          tabIndex="-1"
          placeholder="Please comment"
          id="b_comment"
        ></textarea>
      </FieldShift>
    </>
  );
};

export default MailchimpFields;
