import styled from 'styled-components';

import Text from './Text';

export default styled(Text).attrs(attrs => ({
  as: 'h2',
  color: 'grey1000',
  fontSize: ['28px', '46px'],
  lineHeight: ['31px', '55px'],
  fontWeight: '800',
  letterSpacing: '-0.02em',
  ...attrs
}))``;
