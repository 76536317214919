import styled from 'styled-components';

import Text from './Text';

export default styled(Text).attrs(attrs => ({
  as: 'h1',
  color: 'grey900',
  fontSize: ['32px', '54px'],
  lineHeight: '110%',
  fontWeight: '900',
  letterSpacing: '-0.02em',
  ...attrs
}))``;
