import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { get } from 'lodash';
// import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { space } from 'styled-system';

import Box from './Box';
import InputError from './InputError';

const Wrapper = styled(Box)`
  position: relative;
`;

const Label = styled.label`
  letter-spacing: -0.02em;
  color: ${p => p.theme.colors.grey700};
  font-size: 16px;
  line-height: 150%;
  font-weight: 800;
  margin-bottom: 8px;
  display: block;
`;

const InputField = styled.input`
  height: 50px;
  width: 100%;
  min-width: 100px;
  padding: 17px 18px;
  box-shadow: inset 0px 2px 4px rgba(121, 117, 132, 0.16);
  border-radius: 4px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.danger : theme.colors.grey100};
  background: ${p => p.theme.colors.grey10};
  color: ${p => p.theme.colors.grey700};
  caret-color: ${p => p.theme.colors.grey700};
  outline: none;
  transition: border-color 0.2s;
  font-weight: 600;
  font-size: 16px;
  ${space} ::placeholder {
    color: ${p => p.theme.colors.grey300};
  }

  &:focus {
    border-color: ${p => p.theme.colors.grey400};
  }
`;

const Input = ({ label, mt, mb, ml, mr, width, ...props }) => {
  // const intl = useIntl();

  return (
    <Wrapper mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
      {!!label && <Label htmlFor={props.name}>{label}</Label>}
      <Field {...props}>
        {({ field, form: { errors, touched, isSubmitting } }) => {
          return (
            <InputField
              error={errors[props.name] && touched[props.name]}
              disabled={isSubmitting || props.disabled}
              id={props.name}
              type="text"
              autoCapitalize="off"
              autoCorrect="off"
              placeholder={label}
              {...props}
              {...field}
            />
          );
        }}
      </Field>
      <ErrorMessage name={props.name}>
        {error => (
          <Box mt="10px">
            <InputError>
              {/* {get(error, 'id') ? intl.formatMessage(error) : error} */}
            </InputError>
          </Box>
        )}
      </ErrorMessage>
    </Wrapper>
  );
};

export default Input;
