import styled from 'styled-components';
import { layout, space } from 'styled-system';

export default styled.hr`
  margin: 40px 0 56px;
  width: 100%;
  border: none;
  border-top: 2px solid ${p => p.theme.colors[p.color || 'grey50']};

  @media (max-width: ${p => p.theme.breakpoints.maxSm}) {
    margin: 32px 0 22px;
  }

  && {
    ${space}
    ${layout}
  }
`;
