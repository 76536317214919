import styled from 'styled-components';
import { space, width, fontSize } from 'styled-system';

const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 800;
  font-family: "Articulat CF";
  font-size: 14px;
  color: ${p => (!p.inverted ? p.theme.colors.white : p.theme.colors.purple)};
  height: 30px;
  border-radius: 15px;
  letter-spacing: -0.01em;
  background-color: ${p =>
    p.inverted ? p.theme.colors.white : p.theme.colors.purple};
  padding: 0 14px;
  ${space}
  ${width}
  ${fontSize}
`;

export default Tag;
