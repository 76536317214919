import styled from 'styled-components';

import Text from './Text';

export default styled(Text).attrs(attrs => ({
  as: 'h3',
  color: 'grey1000',
  fontSize: '32px',
  lineHeight: '38px',
  fontWeight: '700',
  letterSpacing: '-0.02em',
  ...attrs
}))``;
