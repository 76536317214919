import React, { useContext } from 'react';
import { useTransition, animated } from 'react-spring';
import styled, { ThemeContext } from 'styled-components';
import { space, layout, fontSize } from 'styled-system';

// import { useSmooth } from '@utils/useSmooth';

import { Spinner } from '.';

const getBackgroundColor = ({
  backgroundColor,
  color: colorProp,
  theme: { colors }
}) => {
  if (backgroundColor) {
    return colors[backgroundColor] || backgroundColor;
  }
  if (colorProp === 'primary') return colors.primary;
  if (colorProp === 'danger') return colors.danger;
  if (colorProp === 'success') return colors.success;
  if (colorProp === 'white') return '#fff';
  if (colorProp === 'login' || colorProp === 'signup') return colors.blueDarker;
  return colors.orange;
};

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${getBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(({ color, link, ...props }) => {
  // if (link) {
  //   return <Link {...props} />;
  // }

  // Eslint cant parse this
  // eslint-disable-next-line
  return <button {...props} type={props.type || 'button'} />;
})`
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: ${p =>
    p.color === 'white'
      ? '0px 4px 6px rgba(0, 0, 0, 0.06), 0px 10px 16px rgba(247, 149, 93, 0.16);'
      : '0px 4px 6px rgba(0, 0, 0, 0.06), 0px 10px 16px rgba(247, 149, 93, 0.16);'};
  border: none;
  outline: none;
  font-weight: 700;
  font-family: Articulat CF;
  color: ${p => p.theme.colors.white};
  background: ${getBackgroundColor};
  transition: background 0.2s;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  height: 50px;
  white-space: nowrap;
  ${space}
  ${layout}
  ${fontSize}

  &:hover {
    background: ${p =>
      p.color === 'login' || p.color === 'signup'
        ? p.theme.colors.blueLighter
        : p.theme.colors.orangeLighter};
  }

  :disabled {
    cursor: not-allowed;
  }
`;

StyledButton.defaultProps = {
  pt: '15px',
  pb: '17px',
  px: '24px',
  fontSize: '16px'
};

const Button = ({
  spinnerBackgroundColor,
  spinnerColor: spinnerColorProp,
  loading,
  children,
  ...props
}) => {
  const themeContext = useContext(ThemeContext);
  // const handleClick = useSmooth(props);

  let spinnerColor;
  let spinnerSize;

  if (spinnerColorProp) {
    spinnerColor = themeContext.colors[spinnerColorProp] || spinnerColorProp;
  } else if (
    props.color === 'primary' ||
    props.color === 'danger' ||
    props.color === 'success'
  ) {
    spinnerColor = themeContext.colors.grey10;
  } else {
    spinnerColor = themeContext.colors.grey1000;
  }

  if (typeof props.fontSize === 'string') {
    spinnerSize = parseInt(props.fontSize, 10);
  } else if (props.fontSize) {
    spinnerSize = props.fontSize;
  } else {
    spinnerSize = 14;
  }

  const transitions = useTransition(!!loading, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { mass: 1, tension: 210, friction: 20 }
  });

  return (
    <StyledButton 
      {...props} 
      // onClick={handleClick}
    >
      {children}
      {transitions.map(
        ({ item, key, props: animationProps }) =>
          item && (
            <animated.div key={key} style={animationProps}>
              <SpinnerWrapper
                backgroundColor={spinnerBackgroundColor}
                color={props.color}
              >
                <Spinner color={spinnerColor} size={spinnerSize} />
              </SpinnerWrapper>
            </animated.div>
          )
      )}
    </StyledButton>
  );
};

export default Button;
