import styled from 'styled-components';
import { textAlign, space, layout } from 'styled-system';

const Section = styled.section`
  ${textAlign}
  width: 100%;
  background: ${p => (p.dark ? p.theme.colors.grey800 : p.theme.colors.grey20)};
  position: relative;
  color: ${p => (p.dark ? p.theme.colors.white : p.theme.colors.black)};
  padding: 200px 0 240px;

  @media (max-width: ${p => p.theme.breakpoints.maxXs}) {
    padding: 100px 0;
  }

  &&& {
    ${space}
    ${layout}
  }
`;

export default Section;
