import React, { useState } from 'react';
import { max } from 'lodash';
import styled from 'styled-components';

const StyledPanel = styled.div`
  width: 100%;

  .section {
    &__container {
      max-width: 100%;
      position: relative;
    }

    &__row {
      display: flex;
      flex-flow: row nowrap;
    }

    &__colLeft {
      width: 41.33%;

      @media (max-width: ${p => p.theme.breakpoints.maxSm}) {
        width: 45%;
      }

      @media (max-width: ${p => p.theme.breakpoints.maxXs}) {
        width: 100%;
      }
    }

    &__images {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f0eff1;
      transition: 0.3s opacity ease-in-out;
      opacity: 0;

      &--active {
        opacity: 1;
        z-index: 1;
      }
    }

    &__colRight {
      align-self: stretch;
      width: 66.66%;

      @media (max-width: ${p => p.theme.breakpoints.maxSm}) {
        width: 55%;
      }

      @media (max-width: ${p => p.theme.breakpoints.maxXs}) {
        display: none;
      }
    }
  }
`;

const Panel = ({ children }) => {
  const [clicked, setClicked] = useState(false);
  const [activeItems, setActiveItem] = useState([0]);

  const renderImages = (item, index) => {
    return (
      <div
        key={`image-${item.props.id}`}
        className={
          max(activeItems) === index ||
          (index === 0 && activeItems.length === 0)
            ? 'section__image section__image--active'
            : 'section__image'
        }
      >
        {item.props.image()}
      </div>
    );
  };

  return (
    <StyledPanel className="section">
      <section className="section__container">
        <div className="section__row">
          <div className="section__colLeft">
            {children.map((child, index) =>
              React.cloneElement(child, {
                key: child.props.id,
                setActiveItem,
                activeItems,
                clicked,
                setClicked,
                index
              })
            )}
          </div>
          <div className="section__colRight">
            <div className="section__images">{children.map(renderImages)}</div>
          </div>
        </div>
      </section>
    </StyledPanel>
  );
};

export default Panel;
