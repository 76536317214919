import styled from 'styled-components';
import {
  space,
  color,
  fontSize,
  position,
  textAlign,
  layout,
  flexbox,
  shadow
} from 'styled-system';

const Flex = styled.div(
  {
    display: 'flex'
  },
  space,
  color,
  fontSize,
  position,
  textAlign,
  layout,
  flexbox,
  shadow
);

Flex.displayName = 'Flex';

Flex.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start'
};

export default Flex;
