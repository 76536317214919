import React from 'react';
import styled, { css } from 'styled-components';

import Flex from './Flex';
import Text from './Typography/Text';

const Option = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  padding: 32px;
  height: 86px;
  cursor: pointer;
  user-select: none;
  position: relative;
  transition: color 0.2s ease-in-out;
  border-color: ${p => p.theme.colors.grey50};
  border-style: solid;
  border-width: 0;
  flex-grow: 1;
  flex-wrap: wrap;

  ${p =>
    p.active
      ? css`
          color: ${p.theme.colors.grey750};
          background-color: ${p.theme.colors.white};
        `
      : css`
          color: ${p.theme.colors.purple};
          background-color: ${p.theme.colors.grey3};
          border-bottom-width: 2px;

          &:first-child {
            border-right-width: 2px;
          }

          &:last-child {
            border-left-width: 2px;
          }

          &:hover {
            color: ${p.theme.colors.grey750};
            background-color: ${p.theme.colors.grey2};
          }
        `}

  @media (max-width: ${p => p.theme.breakpoints.maxXs}) {
    padding: 12px;
    height: 70px;
  }
`;

const Toggle = ({ setMonthly, isMonthly, ...props }) => (
  <Flex flexDirection="row" justifyContent="center" width="100%" {...props}>
    <Option
      onClick={() => {
        setMonthly(true);
      }}
      active={!!isMonthly}
    >
      Billed monthly
    </Option>
    <Option
      onClick={() => {
        setMonthly(false);
      }}
      active={!isMonthly}
    >
      Billed yearly
      <Text color="success" ml="5px" as="span">
        (save 10%)
      </Text>
    </Option>
  </Flex>
);

export default Toggle;
