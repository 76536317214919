import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;

  @media (min-width: ${p => p.theme.breakpoints.sm}) {
    position: absolute;
    top: 48px;
    right: 64px;
  }

  @media (max-width: ${p => p.theme.breakpoints.sm}) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 40px;
  }

  @media (max-width: ${p => p.theme.breakpoints.md}) {
    justify-content: flex-end;
    right: 16px;
    left: 16px;
    top: 16px;
  }
`;

const TopRight = ({ children }) => {
  return <Container>{children}</Container>;
};

export default TopRight;
