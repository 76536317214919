import React from 'react';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';


import Login from '../login';
import Signup from '../signup';

const Routes = () => {

  return (
    <Router>
      <Switch>
        <Route exact path='/login' component={Login} />

        <Route path='/signup' component={Signup} />
        <Route
          path='/'
          exact
          render={() => (
            <Redirect to='/login' />
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
