import styled from 'styled-components';
import { space, layout, typography } from 'styled-system';

const Card = styled.div`
  padding: 40px 48px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(53, 49, 63, 0.08),
    0px 1px 2px rgba(53, 49, 63, 0.04);
  border-radius: 8px;
  ${space}
  ${layout}
  ${typography}
`;

export default Card;
