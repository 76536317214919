import React from 'react';
import styled from 'styled-components';
import { color, margin } from 'styled-system';

const Bar = styled.div`
  background: rgba(228, 226, 228, 0.3);
  height: 4px;
  width: 100%;
  min-width: ${p => p.width || '250px'};
  position: relative;
  ${margin}
  ${color}
`;

const Progress = styled.div.attrs(({ percentage }) => ({
  style: {
    width: `${percentage}%`
  }
}))`
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  transition: width 0.25s ease;
  background: ${p => p.theme.colors.yellow};
  will-change: width;
`;

const ProgressBar = ({ percentage, ...props }) => (
  <Bar {...props}>
    <Progress percentage={percentage} />
  </Bar>
);

export default ProgressBar;
