import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { Form, Formik } from 'formik';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';

import { 
  Box, 
  Card, 
  // Seo, 
  Flex, 
  // Link, 
  Text, 
  Button, 
  Input 
} from '@components';
import { Hero, HeroWrapper, TopRight } from '@layouts/Signup';
import { getError } from '@utils/helpers';
import Logo from '../../static/images/gymstudio-logo.svg';

const LogoContainer = styled.div`
  display: flex;
`;

const messages = defineMessages({
  title: 'Log in to your account',
  description:
    'Forgot your studio login? No worries, request a login link to your studio here.',
  metaTitle: 'Login',
  metaDescription:
    'Sign in to your GymStudio account. Forgot your studio login? No worries, request a login link to your studio here.',
  emailLabel: 'Email',
  success:
    'We have sent a link to log in to the studio(s) on which you have an account to "{email}".',
  sendButton: 'Send me a login link',
  needHelp: 'Having trouble accessing your studio? Contact us at {link}.',
  noAccount: 'Don’t have an account?',
  signupButton: 'Sign up for free'
});

axios.defaults.withCredentials = true;

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required()
});

const Login = () => {
  // const intl = useIntl();
  const [generalError, setGeneralError] = useState(null);
  const [generalSuccess, setGeneralSuccess] = useState(null);

  useEffect(() => {
    console.log(process.env);
  }, []);

  const login = async (values, setSubmitting) => {
    try {
      await axios.post(`${process.env.REACT_APP_NODE_API_URL}/magic-link`, values);

      setGeneralSuccess(true);
    } catch (err) {
      setGeneralError(getError(err));
    }

    setSubmitting(false);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setGeneralError(null);

    login(values, setSubmitting);
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    window.location.href = '/signup';
  };

  return (
    <>
      {/* <Seo
        title={intl.formatMessage(messages.metaTitle)}
        description={intl.formatMessage(messages.metaDescription)}
      /> */}

      <HeroWrapper>
        <Hero>
          <Box 
            width="100%" maxWidth="464px" px={[0, '20px']}
            style={{ display: "flex", flexDirection: "column", alignItems: "center"}}
          >
            <LogoContainer>
              <img src={Logo} alt="Gym Studio Logo" />
            </LogoContainer>
            <Card px={['24px', '48px']} my="32px" textAlign='left'>
              <Text
                fontSize={['24px', '28px']}
                lineHeight="100%"
                as="h1"
                fontWeight="800"
                letterSpacing="-0.02em"
                mb={['16px', '32px']}
              >
                {messages.title}
              </Text>
              <Text
                mb="24px"
                lineHeight="150%"
                fontSize={['16px', '18px']}
                fontWeight="600"
                as="p"
                color="grey600"
              >
                {messages.description}
              </Text>
              <Formik
                validateOnBlur
                validateOnChange={false}
                initialValues={{
                  email: ''
                }}
                validationSchema={FormSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, values }) => (
                  <Form autoComplete="off">
                    <Input
                      name="email"
                      label={messages.emailLabel}
                      disabled={generalSuccess}
                      mb="16px"
                    />
                    <Box mt={['24px', '32px']}>
                      {!!generalError && (
                        <Box mb={['24px', '32px']}>
                          <Text color="danger">{generalError}</Text>
                        </Box>
                      )}
                      {!!generalSuccess && (
                        <Box mb={['24px', '32px']}>
                          <Text color="success">
                            {messages.success}
                          </Text>
                        </Box>
                      )}
                      <Button
                        width="100%"
                        px="30px"
                        type="submit"
                        loading={isSubmitting}
                        disabled={isSubmitting || generalSuccess}
                      >
                        {messages.sendButton}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Card>

            <Flex
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              maxWidth="270px"
              mx="auto"
            >
              {/* <Text
                lineHeight="150%"
                fontSize="12px"
                fontWeight="600"
                color="grey600"
                as="p"
              >
                <FormattedMessage
                  {...messages.needHelp}
                  values={{
                    link: (
                      <Link
                        fontWeight="bold"
                        as="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="mailto:support@blossom.so"
                      >
                        support@blossom.so
                      </Link>
                    )
                  }}
                />
              </Text> */}
            </Flex>
          </Box>
        </Hero>

        <TopRight>
          <Text
            lineHeight="19px"
            fontSize="16px"
            fontWeight="600"
            color="grey600"
            mr={[0, '24px']}
            mb={['16px', 0]}
          >
            {messages.noAccount}
          </Text>
          <Link onClick={handleSignupClick}>
            <Button color="signup">
                {messages.signupButton}
            </Button>
          </Link>
        </TopRight>
      </HeroWrapper>
    </>
  );
};

export default Login;
