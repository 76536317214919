import React from 'react';
import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  0% {
    opacity: 1
  }
  100% {
    opacity: 0;
  }
`;

const getBars = ({ countBars, color, size }) => {
  const bars = [];
  for (let i = 0; i < countBars; i += 1) {
    const rotate = (360 / countBars) * i;
    const translate = -(size / 1.9);
    bars.push(
      <Bar
        countBars={countBars}
        color={color}
        size={size}
        rotate={rotate}
        translate={translate}
        key={i.toString()}
        index={i}
      />
    );
  }
  return bars;
};

export const Spinner = ({ size = 30, color = '#fff', loading = true }) => {
  const countBars = 8;

  if (loading) {
    return (
      <Wrapper size={size}>
        {getBars({
          countBars,
          color,
          size
        })}
      </Wrapper>
    );
  }

  return null;
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;

const Bar = styled.div`
  position: absolute;
  width: ${p => p.size / 7}px;
  height: ${p => p.size / 2.4}px;
  background-color: ${props => props.color};
  opacity: 0.05;
  border-radius: 4px;
  transform: rotate(${props => props.rotate}deg)
    translate(0, ${props => props.translate}px);
  animation: ${fade} ${props => props.countBars * 0.09}s linear infinite;
  animation-delay: ${props => props.index * 0.09}s;
`;

export default Spinner;
