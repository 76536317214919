import styled from 'styled-components';
import {
  typography,
  maxWidth,
  color,
  space,
  system,
  layout,
  flexbox
} from 'styled-system';

const Text = styled.span(
  typography,
  maxWidth,
  color,
  space,
  flexbox,
  layout,
  system({ textTransform: true })
);

export default Text;
