import React from 'react';
import { without, max } from 'lodash';
import Observer from 'react-intersection-observer';
import styled from 'styled-components';

import { Box, Flex, P, Text } from '..';

const StyledItem = styled.div``;

const StepSection = styled.div`
  text-align: left;
  max-width: 312px;

  @media (min-width: ${p => p.theme.breakpoints.sm}) {
    cursor: pointer;
  }
`;

const ArrowIcon = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.66666 16H28.6667"
      stroke="#4B3479"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M15.6667 29L28.6667 16L15.6667 3"
      stroke="#4B3479"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
  </svg>
);

const Item = ({
  index,
  title,
  description,
  setActiveItem,
  activeItems,
  clicked,
  setClicked
}) => {
  const isActive =
    max(activeItems) === index || (index === 0 && activeItems.length === 0);
  const onClick = () => {
    setClicked(true);
    setActiveItem([index]);
  };
  const changeItem = inView => {
    if (!clicked) {
      if (inView) {
        setActiveItem([...activeItems, index]);
      } else if (activeItems.length === 0) {
        setActiveItem([0]);
      } else {
        setActiveItem(without(activeItems, index));
      }
    }
  };

  return (
    <StyledItem className="item" id={index}>
      <Observer
        rootMargin="0px 0px -40%"
        threshold={1}
        tag="div"
        onChange={changeItem}
      >
        <Flex key={title} flexDirection="row" mb="40px">
          <Box
            minWidth={['42px', '64px', '112px']}
            pr={['18px', '18px', '34px']}
            textAlign="right"
            m="0"
          >
            {isActive && (
              <div className="hidden-mobile">
                <ArrowIcon />
              </div>
            )}
            <Box className="visible-mobile">
              <ArrowIcon width="24px" height="24px" />
            </Box>
          </Box>
          <StepSection onClick={onClick}>
            <Text
              as="h4"
              fontWeight="700"
              fontSize={['20px', '24px']}
              lineHeight={['20px', '29px']}
              color={['grey800', isActive ? 'grey800' : 'grey600']}
              mb={['16px', '8px']}
            >
              {title}
            </Text>
            <P
              fontSize="18px"
              fontWeight="500"
              letterSpacing="-0.02em"
              color={['grey600', isActive ? 'grey600' : 'grey400']}
            >
              {description}
            </P>
          </StepSection>
        </Flex>
      </Observer>
    </StyledItem>
  );
};

export default Item;
