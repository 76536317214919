import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
// import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import { position } from 'styled-system';

import { Box, Flex, Checks, H2, Text, Button } from '@components';
import { calendlyLinkProps } from '@utils/calendly';

const Float = styled.div`
  user-select: none;
  z-index: 2;
  position: absolute;
  ${position}

  @media (max-width: ${p => p.theme.breakpoints.maxSm}) {
    &.image--blossom {
      display: none;
    }
  }
`;

const ContainerWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding-top: 128px;
  padding-bottom: 128px;

  @media (max-width: ${p => p.theme.breakpoints.maxSm}) {
    padding-top: 80px;
    padding-top: 80px;
  }
`;

const SignupSection = ({
  content: { title, subtext, sign_up_button, book_demo_button, checks },
  dark,
  images,
  mattTop
}) => {
  // const { branch, matt, mattSmall } = useStaticQuery(
  //   graphql`
  //     query {
  //       branch: file(relativePath: { eq: "blossom-branch.png" }) {
  //         childImageSharp {
  //           fixed(traceSVG: { color: "#b38e74" }, height: 453, width: 922) {
  //             ...GatsbyImageSharpFixed_withWebp_tracedSVG
  //           }
  //         }
  //       }
  //       matt: file(relativePath: { eq: "matt.png" }) {
  //         childImageSharp {
  //           fixed(traceSVG: { color: "#211447" }, height: 259, width: 738) {
  //             ...GatsbyImageSharpFixed_withWebp_tracedSVG
  //           }
  //         }
  //       }
  //       mattSmall: file(relativePath: { eq: "matt.png" }) {
  //         childImageSharp {
  //           fixed(traceSVG: { color: "#211447" }, height: 70, width: 188) {
  //             ...GatsbyImageSharpFixed_withWebp_tracedSVG
  //           }
  //         }
  //       }
  //     }
  //   `
  // );
  const content = (
    <Box maxWidth="593px" px="20px">
      <H2 mb="16px" color={dark ? 'grey5' : ''}>
        {title}
      </H2>
      <Text
        as="p"
        lineHeight={['156%', '150%']}
        fontSize={['16px', '24px']}
        color={dark ? 'grey50' : 'grey700'}
        letterSpacing={['-0.02em', '-0.01em']}
      >
        {subtext}
      </Text>
      <Flex flexDirection="row" justifyContent="center" mt={['32px', '40px']}>
        <Button link to="/signup/" mr="24px">
          {sign_up_button}
        </Button>
        <Button as="a" {...calendlyLinkProps} color="white">
          {book_demo_button}
        </Button>
      </Flex>
      <Checks
        mt={['32px', '24px']}
        pros={checks.map(c => c.text)}
        color={dark ? 'grey500' : ''}
      />
    </Box>
  );

  if (images) {
    const mattProps = mattTop
      ? {
          top: '-80px',
          left: ['-140px', '-560px', '-380px']
        }
      : {
          bottom: '-64px',
          left: ['-140px', '-420px', '-380px']
        };

    return (
      <ContainerWrapper>
        {images && (
          <Float
            top="-80px"
            left={{ _: 'calc(100% - 340px)', lg: 'calc(100% - 400px)' }}
            className="image--blossom"
          >
            {/* <Parallax y={[0, 20]}> */}
              {/* <Img
                alt="Blossom branch"
                height="453"
                width="423"
                fixed={branch.childImageSharp.fixed}
              /> */}
            {/* </Parallax> */}
          </Float>
        )}
        {content}
        {images && (
          <>
            <Float
              {...mattProps}
              style={{ transform: 'rotate(27deg)' }}
              className="hidden-mobile"
            >
              {/* <Parallax y={[0, -20]}> */}
                {/* <Img
                  alt="Blossom yoga mat"
                  height="259"
                  width="738"
                  fixed={matt.childImageSharp.fixed}
                /> */}
              {/* </Parallax> */}
            </Float>

            <Float
              top="-40px"
              left="-40px"
              style={{ transform: 'rotate(27deg)' }}
              className="visible-mobile"
            >
              {/* <Img
                alt="Small Blossom yoga mat"
                height="70"
                width="188"
                fixed={mattSmall.childImageSharp.fixed}
              /> */}
            </Float>
          </>
        )}
      </ContainerWrapper>
    );
  }

  return content;
};

export default SignupSection;
