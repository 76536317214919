import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    background-color: ${p => p.theme.colors.grey800};
  }

  main {
    background-color: ${p => p.theme.colors.grey20};
  }

  body {
    overflow: initial !important;
  }

  body.menu-open {
    overflow: hidden !important;
  }

  html[data-ios] {
    -webkit-overflow-scrolling: touch;
  }

  .grecaptcha-badge { visibility: hidden; }

  @media (max-width: ${p => p.theme.breakpoints.maxXs}) {
    .hidden-mobile {
      display: none;
    }
  }

  @media (min-width: ${p => p.theme.breakpoints.sm}) {
    .visible-mobile {
      display: none;
    }
  }
`;

export default GlobalStyle;
