import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

import { Box, Text } from '@components';
import { useMeasure } from '@utils/useMeasure';

const Outer = styled(Box).attrs({
  className: 'collapsible'
})`
  width: 100%;
  margin-top: -2px;
  border-top: 2px solid ${p => p.theme.colors.grey100};
  border-bottom: 2px solid ${p => p.theme.colors.grey100};
`;

const Drawer = styled.button.attrs({
  className: 'drawer'
})`
  padding: 40px 0;
  width: 100%;
  text-align: left;
  outline: none !important;

  > div {
    transition: opacity 0.18s;
  }

  svg {
    min-width: 25px;
    margin-left: 24px;
  }

  &:hover {
    > div {
      opacity: 0.8;
    }
  }

  @media (max-width: ${p => p.theme.breakpoints.maxSm}) {
    padding: 24px 0;

    svg {
      min-width: 18px;
      width: 18px;
      height: 18px;
      margin-left: 24px;
    }
  }
`;

const Content = animated(styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  overflow: hidden;
`);

const Collapsible = ({ isOpen, toggle, children, title, ...props }) => {
  const [bind, { height: viewHeight }] = useMeasure();

  const style = useSpring({
    from: { height: 0 },
    to: {
      height: isOpen ? viewHeight + 40 : 0
    },
    config: { mass: 1, tension: 400, friction: 38 }
  });

  return (
    <Outer {...props}>
      <Drawer onClick={toggle}>
        <Text
          as="h4"
          fontWeight="700"
          fontSize={['20px', '24px']}
          lineHeight={['20px', '29px']}
          color="grey800"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {title}
          {isOpen ? (
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.957031 13H24.957"
                stroke="#3F3149"
                strokeWidth="4"
                strokeMiterlimit="10"
              />
            </svg>
          ) : (
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.957031 13H24.957"
                stroke="#3F3149"
                strokeWidth="4"
                strokeMiterlimit="10"
              />
              <path
                d="M12.957 25.0003V0.933594"
                stroke="#3F3149"
                strokeWidth="4"
                strokeMiterlimit="10"
              />
            </svg>
          )}
        </Text>
      </Drawer>
      <Content isOpen={isOpen} style={style}>
        <div {...bind}>{children}</div>
      </Content>
    </Outer>
  );
};

export default Collapsible;
