/* eslint-disable prefer-destructuring */
const theme = {
  name: 'default',
  colors: {
    brand: '#211447',
    orange: '#F5874E',
    orangeLighter: '#FAC8AF',
    blueDarker: '#06283D',
    blueLighter: 'rgba(70, 92, 125, 0.6)',
    purple: '#4B3479',
    yellow: '#F6C300',
    yellowLighter: '#ffdb54',
    red: '#f44248',
    beige: 'rgba(246, 195, 0, 0.1)',
    white: '#fff',
    whiteFake: '#FAFAFC',
    greyLight: '#EDEDEE',
    link: '#0068A5',
    link50: '#C8F4FA',
    danger: '#911444',
    danger50: '#FEEBEB',
    warning: '#F6C300',
    warning50: '#FEF7DF',
    success: '#619F13',
    success50: '#EFF9E1',
    black: '#1F1424',
    grey: '#B5B2BB',
    grey2: '#f6f6f6',
    grey3: '#F8F7F7',
    grey5: '#FCFCFC',
    grey10: '#FBFBFB',
    grey20: '#F0EFF1',
    grey30: '#cbc8cd',
    grey50: '#EEEDF0',
    grey100: '#D4D3D8',
    grey200: '#B8B6BF',
    grey300: '#A4A1AA',
    grey400: '#928E9E',
    grey500: '#797584',
    grey600: '#635F6F',
    grey700: '#514C5D',
    grey730: '#4E4C4F',
    grey750: '#35313F',
    grey800: '#231829',
    grey900: '#222027',
    grey1000: '#201E22',
    grey1100: '#08050A',
    overlay: 'rgba(0, 0, 0, 0.7)',
    primary40: '#F8F5FC'
  },
  shadows: {
    z1:
      '0px 0px 1px 0px rgba(10, 22, 70, 0.06), 0px 1px 1px 0px rgba(10, 22, 70, 0.1)',
    z2:
      '0px 0px 1px 0px rgba(10, 22, 70, 0.06), 0px 3px 3px -1px rgba(10, 22, 70, 0.1)',
    z3:
      '0px 0px 1px 0px rgba(10, 22, 70, 0.06), 0px 6px 6px -1px rgba(10, 22, 70, 0.1)',
    z4:
      '0px 0px 1px 0px rgba(10, 22, 70, 0.06), 0px 16px 16px -1px rgba(10, 22, 70, 0.1)',
    z5:
      '0px 0px 1px 0px rgba(10, 22, 70, 0.06), 0px 32px 40px -2px rgba(10, 22, 70, 0.12)'
  },
  layout: {
    sideMargin: '15%'
    // sideMargin: "242px"
  },
  gradients: {
    landing: 'linear-gradient(45deg, #e6e5e8 0%, #e9e8eb 100%);'
  },
  fontSizes: ['14px', '18px', '23px', '26px', '36px', '72px'],
  breakpoints: ['768px', '992px', '1200px', '1700px']
};

theme.breakpoints.xs = '350px';
theme.breakpoints.sm = theme.breakpoints[0];
theme.breakpoints.md = theme.breakpoints[1];
theme.breakpoints.lg = theme.breakpoints[2];
theme.breakpoints.xl = theme.breakpoints[3];

theme.breakpoints.maxXs = '767px';
theme.breakpoints.maxSm = '991px';
theme.breakpoints.maxMd = '1199px';
theme.breakpoints.maxLg = '1699px';

export default theme;
