import styled from 'styled-components';

import Text from './Text';

export default styled(Text).attrs(attrs => ({
  as: 'p',
  fontWeight: '600',
  color: 'grey600',
  fontSize: '16px',
  lineHeight: '150%',
  ...attrs
}))``;
