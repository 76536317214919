import logo from './logo.svg';
import './App.css';
import Routes from './modules/router';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './style/GlobalStyle';
import theme from './style/theme';
import './style/reset.css';

import './media/fonts/ArticulatCF/Articulat.css';
import './media/fonts/ManifoldCF/stylesheet.css';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <GlobalStyle />
        <Routes />   
        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header> */}
      </div>
    </ThemeProvider>

  );
}

export default App;
