import { get } from 'lodash';

export const isEmpty = obj =>
  Object.entries(obj).length === 0 && obj.constructor === Object;

// Return error message from response
export const getError = err => {
  const res = err.response;

  let error = get(
    res,
    'data.fault.message',
    get(
      res,
      'data.error.message',
      String(err) || 'An unknown error has occured.'
    )
  );

  if (error === 'Error: Network Error') {
    error = 'Could not connect to the server!';
  }

  return error;
};

export const propsWithUniqueKey = (props, key) =>
  Object.assign(props || {}, { key });
