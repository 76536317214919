import styled from 'styled-components';
import {
  space,
  color,
  fontSize,
  position,
  flexBasis,
  flexDirection,
  flex,
  justifySelf,
  alignSelf,
  order,
  layout,
  grid,
  textAlign,
  shadow
} from 'styled-system';

const Box = styled.div(
  {
    boxSizing: 'border-box',
    minWidth: 0,
    margin: '0 auto'
  },
  space,
  color,
  fontSize,
  position,
  flexBasis,
  flexDirection,
  flex,
  justifySelf,
  alignSelf,
  order,
  layout,
  grid,
  textAlign,
  shadow
);

export default Box;
